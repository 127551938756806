import BasePlugin from '../BasePlugin'

export default class AddressProgramAdjustment extends BasePlugin {
  async execute () {
    let data = {
      'addressProgramAdjustmentId': this.context.getModel().attr_3392_,
      'adjustablePeriod': this.context.getModel().attr_3394_
    }
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddressProgramAdjustment`,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  }
}
